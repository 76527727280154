import Network from '@/services/network'
import {useQuery} from '@/services/utils/query'

export default function handleRole() {
    const network = new Network;
    const {allQuery} = useQuery()

    const fetchCompanyDefaultRoles = (query) => {
        return network.api('get', '/default-roles' + query);
    }

    const roleList = (companyId) => {
        return network.api('get', '/roles?company_id='+companyId);
    }

    const sendRoleRequest = (data) => {
        return network.api('post', '/roles', data);
    }

    const updateRoleRoleRequest = (data, roleId) => {
        return network.api('put', `/roles/${roleId}`, data);
    }

    const deleteRole = (id, companyId) => {
        return network.api('delete', `/roles/${id}?company_id=${companyId}`);
    }
    return {
        fetchCompanyDefaultRoles,
        allQuery,
        roleList,
        sendRoleRequest,
        updateRoleRoleRequest,
        deleteRole
    }
}
