<template>
    <tr v-show="!item.isEdit" @click="openEditLine(index)">
        <td class="pl-10">
            <div class="col">
                <strong>
                    <h6>{{ item.name }}</h6>
                </strong>
                {{ item.description }}
            </div>
        </td>
        <td class="text-center">{{ item.quantity }}</td>
        <td class="text-center">{{ item.rate }}</td>
        <td class="text-center">{{ item.amount }}</td>
        <td class="text-center">{{ item.discount_percent }}%</td>
        <td class="text-center">{{ item.discount_amount }}</td>
        <td class="text-center">{{ amountAfterDiscount }}</td>
        <td class="text-center">{{ item.vat }}%</td>
        <td class="text-center">{{ item.vat_amount }}</td>
        <td class="text-center">{{ totalAmount }}</td>
        <td>
            <div>
                <button @click="deleteItem(index)" class="btn btn-sm btn-dlt">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </td>
    </tr>
    
    <tr v-show="item.isEdit" :class="item.isEdit && 'tr-box-shadow'">
        <td>
            <div class="col">
                <strong>
                    <h6>{{ item.name }}</h6>
                </strong>
                {{ item.description }}
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.quantity"
                    type="number" class="form-control"
                    placeholder="Qty" :min="0"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.rate"
                    type="number" class="form-control"
                    placeholder="Rate" :min="0"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    readonly
                    v-model="amount"
                    name="amount text-right"
                    type="number"
                    class="form-control text-right"
                />
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.discount_percent"
                    type="number" class="form-control"
                    placeholder="Discount(%)" :min="0"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    v-model="item.discount_amount"
                    name="discount-amount"
                    type="number"
                    class="form-control text-right"
                    min="0"
                />
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    v-model="amountAfterDiscount"
                    name="amount"
                    type="number"
                    class="form-control text-right"
                />
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    v-model="item.vat"
                    id="vat"
                    as="select"
                    name="vat"
                    class="form-select"
                >
                    <option
                        v-for="(vat, i) in vatRate"
                        :value="vat.value"
                        :key="i"
                    >
                        {{vat.label}}
                    </option>
                </vField>
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    v-model="item.vat_amount"
                    name="vat_amount"
                    type="number"
                    class="form-control text-right"
                />
            </div>
        </td>
        <td>
            <div class="td-inline">
                <vField
                    v-model="totalAmount"
                    readonly
                    name="total_amount"
                    type="number"
                    class="form-control text-right"
                />
            </div>
        </td>
        <td>
            <div>
                <button @click="deleteItem(index)" class="btn btn-sm btn-dlt">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </td>
    </tr>

</template>
<script setup>
import {computed, inject, onMounted, ref, watch} from "vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handlePurchase from "@/services/modules/purchase";
import {useRoute} from "vue-router";
import handleInventory from "@/services/modules/inventory";

    const props = defineProps(['item', 'index', 'onOpenEditItem', 'onDeleteItem', 'vatRate', 'products', 'tableItems'])
    const emit = defineEmits(['onOpenEditItem', 'onDeleteItem']);
    const {fetchProductList} = handlePurchase()
    const {getProduct} = handleInventory()
    const $route = useRoute();
    const showError = inject('showError')

    const companyId = $route.params.companyId
    const additionalProductQuery = {
        product_type: "services",
        from: "billing"
    }
    const openEditLine = (i) => {
        emit('onOpenEditItem', i)
    }

    const deleteItem = (i) => {
        emit('onDeleteItem', i)
    }
    const amount = computed(() => props.item.amount = props.item.rate * props.item.quantity)

    const discountPercent = computed(() => props.item.discount_percent);

    const amountAfterDiscount = computed(() => {
        const discountAmount = props.item.discount_amount === '' ? 0 : parseFloat(props.item.discount_amount)
        if(discountAmount === 0) {
          return amount.value;
        }
        return amount.value - discountAmount
    })

    const vatAmount = computed(() => {
        if(props.item.vat === 0) {
            return 0
        }

        if(props.item.vat) {
            return (props.item.vat/100 * amountAfterDiscount.value).toFixed(2)
        }
        return (props.item.vat/100 * amountAfterDiscount.value).toFixed(2);
    })

    const totalAmount = computed(() => {
        if(props.item.vat == 0) {
            return props.item.total_amount = amountAfterDiscount.value
        }
        return props.item.total_amount = parseInt(amountAfterDiscount.value) + parseInt(props.item.vat_amount)
    })

    watch(vatAmount, (newValue, oldValue) => {
        if(isNaN(oldValue)){
            return props.item.vat_amount  = props.item.vat_amount;
        }
        if(isNaN(oldValue)){
          
        }else {
          props.item.vat_amount  = newValue;
        }
    })

    watch(discountPercent, (value) => {
        let discount = 0;
        value = parseFloat(value);
        if(value && value > 0 && amount.value > 0) {
          discount = amount.value * (value/100);
        }
        props.item.discount_percent = value;
        props.item.discount_amount = discount.toFixed(2);
    })

</script>
<style scoped>
.btn-dlt{
    color: #ea5455;
}
</style>