import Network from '@/services/network'

export default function handleHospitalBilling() {
    const network = new Network;

    // OPD Billing
    const fetchOPDBillings = (query) => {
        return network.api('get', '/hospital/opd-billings' + query);
    }
    const storeOPDBilling = (data) => {
        return network.api('post', '/hospital/opd-billings', data);
    }
    const updateOPDBilling = (data) => {
        return network.api('put', '/hospital/opd-billings/' + data.id, data);
    }
    const returnOPDBilling = (data) => {
        return network.api('post', '/hospital/return/opd-billings', data);
    }
    const returnBillings = (data) => {
        return network.api('post', '/hospital/return/billings', data);
    }

    const fetchIpdRegisterList = (query) => {
        return network.api('get', '/hospital/ipd-registers/all' + query);
    }

    const fetchDoctorOrders = (query) => {
        return network.api('get', '/hospital/doctor-orders' + query);
    }

    const fetchDoctorMedicineOrder = (orderId, query) => {
        return network.api('get', `/hospital/doctor-orders/${orderId}/medicines${query}`);
    }

    return {
        returnBillings,
        storeOPDBilling,
        fetchOPDBillings,
        updateOPDBilling,
        returnOPDBilling,
        fetchDoctorOrders,
        fetchIpdRegisterList,
        fetchDoctorMedicineOrder
    }
}
