import Network from '@/services/network'
import { ref } from 'vue'

export default function handleReceipt() {
    const network = new Network;
    const receiptLoading = ref(false)

    const fetchPayments = (ledgerMasterId) => {
      return network.api('get', '/payments/'+ledgerMasterId);
    }

    const fetchSingleReceipt = (ledgerMasterId) => {
      return network.api('get', '/receipts/'+ledgerMasterId);
    }

    const createReceipt = (data) => {
      return network.api('post', '/receipts', data);
    }

    const editReceipt = (id,data) => {
      return network.api('post', '/receipts/'+id, data);
    }

    const fetchLedgerMaster = (companyId) => {
      return network.api('get', '/reports/ledger-master?company_id='+companyId);
    }

    const fetchCashAndBank = (companyId) => {
      return network.api('get', '/heads/cash-and-banks?company_id='+companyId);
    }

    const fetchAccountHeads = (companyId) => {
      return network.api('get', '/account-heads?company_id='+companyId);
    }

    return {
        fetchPayments,
        fetchLedgerMaster,
        fetchCashAndBank,
        fetchSingleReceipt,
        createReceipt,
        fetchAccountHeads,
        editReceipt,
        receiptLoading
    }
}